exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-templates-article-js": () => import("./../../../src/templates/article.js" /* webpackChunkName: "component---src-templates-article-js" */),
  "component---src-templates-career-js": () => import("./../../../src/templates/career.js" /* webpackChunkName: "component---src-templates-career-js" */),
  "component---src-templates-category-bottles-js": () => import("./../../../src/templates/category-bottles.js" /* webpackChunkName: "component---src-templates-category-bottles-js" */),
  "component---src-templates-category-kegs-js": () => import("./../../../src/templates/category-kegs.js" /* webpackChunkName: "component---src-templates-category-kegs-js" */),
  "component---src-templates-category-preforms-js": () => import("./../../../src/templates/category-preforms.js" /* webpackChunkName: "component---src-templates-category-preforms-js" */),
  "component---src-templates-category-water-coolers-js": () => import("./../../../src/templates/category-water-coolers.js" /* webpackChunkName: "component---src-templates-category-water-coolers-js" */),
  "component---src-templates-main-bottles-js": () => import("./../../../src/templates/main-bottles.js" /* webpackChunkName: "component---src-templates-main-bottles-js" */),
  "component---src-templates-main-kegs-js": () => import("./../../../src/templates/main-kegs.js" /* webpackChunkName: "component---src-templates-main-kegs-js" */),
  "component---src-templates-main-preforms-js": () => import("./../../../src/templates/main-preforms.js" /* webpackChunkName: "component---src-templates-main-preforms-js" */),
  "component---src-templates-main-water-coolers-js": () => import("./../../../src/templates/main-water-coolers.js" /* webpackChunkName: "component---src-templates-main-water-coolers-js" */),
  "component---src-templates-market-js": () => import("./../../../src/templates/market.js" /* webpackChunkName: "component---src-templates-market-js" */),
  "component---src-templates-page-about-js": () => import("./../../../src/templates/page-about.js" /* webpackChunkName: "component---src-templates-page-about-js" */),
  "component---src-templates-page-careers-js": () => import("./../../../src/templates/page-careers.js" /* webpackChunkName: "component---src-templates-page-careers-js" */),
  "component---src-templates-page-contact-js": () => import("./../../../src/templates/page-contact.js" /* webpackChunkName: "component---src-templates-page-contact-js" */),
  "component---src-templates-page-home-js": () => import("./../../../src/templates/page-home.js" /* webpackChunkName: "component---src-templates-page-home-js" */),
  "component---src-templates-page-js": () => import("./../../../src/templates/page.js" /* webpackChunkName: "component---src-templates-page-js" */),
  "component---src-templates-page-legal-js": () => import("./../../../src/templates/page-legal.js" /* webpackChunkName: "component---src-templates-page-legal-js" */),
  "component---src-templates-page-market-js": () => import("./../../../src/templates/page-market.js" /* webpackChunkName: "component---src-templates-page-market-js" */),
  "component---src-templates-page-news-js": () => import("./../../../src/templates/page-news.js" /* webpackChunkName: "component---src-templates-page-news-js" */),
  "component---src-templates-page-resource-js": () => import("./../../../src/templates/page-resource.js" /* webpackChunkName: "component---src-templates-page-resource-js" */),
  "component---src-templates-page-sustainability-calculator-js": () => import("./../../../src/templates/page-sustainability-calculator.js" /* webpackChunkName: "component---src-templates-page-sustainability-calculator-js" */),
  "component---src-templates-page-sustainability-js": () => import("./../../../src/templates/page-sustainability.js" /* webpackChunkName: "component---src-templates-page-sustainability-js" */),
  "component---src-templates-product-bottles-js": () => import("./../../../src/templates/product-bottles.js" /* webpackChunkName: "component---src-templates-product-bottles-js" */),
  "component---src-templates-product-kegs-js": () => import("./../../../src/templates/product-kegs.js" /* webpackChunkName: "component---src-templates-product-kegs-js" */),
  "component---src-templates-product-preforms-js": () => import("./../../../src/templates/product-preforms.js" /* webpackChunkName: "component---src-templates-product-preforms-js" */),
  "component---src-templates-product-water-coolers-js": () => import("./../../../src/templates/product-water-coolers.js" /* webpackChunkName: "component---src-templates-product-water-coolers-js" */),
  "component---src-templates-resource-js": () => import("./../../../src/templates/resource.js" /* webpackChunkName: "component---src-templates-resource-js" */),
  "component---src-templates-team-js": () => import("./../../../src/templates/team.js" /* webpackChunkName: "component---src-templates-team-js" */)
}

